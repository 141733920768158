import { makeAutoObservable, computed, action, runInAction, observable, makeObservable } from "mobx"
import { Screen } from '../Screen'
import { mergeDomain } from '../../common'

export class SpreadsheetScreen extends Screen {
    selected_records = []
    list_type = ""
    smart_sort_options = []
    field_instance = false
    row_height = 40
    
    
    get all_records_selected(){
        return this.data.records.length && this.data.records.length === this.selected_records.length
    }

    get header_row_height(){
        return (this.filters && this.filters.quick_filters.length) ? 80:40
    }
    get group_by_fields(){
        return this.fields.filter(field => field.group_by)
    }

    get group_by_options(){
        return this.group_by_fields.map(function(field){return {'value':field.name, 'label':field.description}})
    }
    constructor(group,
                view, 
                connection, 
                navigate, 
                initial_search, 
                route_state, 
                is_modal, 
                parent, 
                initialize_fields = true, 
                initialize_actions = true, 
                initialize_data=true, 
                initialize_callback=false, 
                fileHandler=false,
                data_callback=false,
                on_load_data=false,
                context={}
                ) {
        super(  
            group, 
            view, 
            connection, 
            navigate, 
            initial_search, 
            route_state, 
            is_modal, 
            parent, 
            initialize_fields, 
            initialize_actions, 
            initialize_data, 
            initialize_callback, 
            fileHandler,
            data_callback,
            on_load_data,
            context)
        this.pivot_table = view.pivot_table
        this.list_type = view.list_type
        this.smart_sort_options = view.smart_sort_options ? view.smart_sort_options:[]
        makeObservable(this, {
            set_selected_record: action,
            add_selected_records: action,
            set_field_instance:action,
            remove_selected_records: action,
            set_row_height:action,
            selected_records: observable,
            field_instance:observable,
            list_type:observable,
            row_height:observable,
            reorder_columns:action

        })

    }

    set_field_instance(field){
        this.field_instance = field
    }
    record_index(record){
        return this.data.records.indexOf(record)
    }
    is_selected(id) {

        return this.selected_records.findIndex(rec => rec.id === id) >= 0

    }
    
    set_row_height(height){
        this.row_height = height
    }

    set_selected_record(records) {
        this.selected_records = records;
        if(this.selected_records.length){
            this.set_active_record(this.selected_records[this.selected_records.length - 1])    
        }
        else{
            this.set_active_record(false)
        }
        
    }
    add_selected_records(records) {
        runInAction(() => {
            records.forEach(function (i) {
                this.selected_records.push(i)
            }.bind(this))

        })

    }
    remove_selected_records(records) {
        records.forEach(function (i) {
            let rec = this.selected_records.indexOf(i)
            this.selected_records.splice(rec, 1);
        }.bind(this))
    }

    

    changeSelection({ id = 0, index = 0, toogle = false, range = false }) {
        const record = id ? this.get_record_by_id(id) : this.get_record_by_index(index)
        const selected = this.is_selected(record.id)

        const get_range = (to)=>{
            let res = [...this.selected_records]
            let from = this.selected_records.length ? this.record_index(this.selected_records[this.selected_records.length-1]):to
            
            
            let start = to<from ? to:from
            let end = to<from ? from:to
            
            while(start <= end){
                const record = this.get_record_by_index(start)
                if(!this.is_selected(record.id)){
                    res.push(record)              
                }
                 
                start+=1
            }
            return res
            
        }

        if (toogle) {
            if(!selected){
                this.set_selected_record([record])
            }
            else{
                if(this.selected_records.length>1){
                    this.set_selected_record([record])
                }
                else{
                    this.set_selected_record([])
                }
                
            }
            
        }
        else {
            if(range){
                
                this.set_selected_record(get_range(index))

            }
            else if (!selected) {
                this.add_selected_records([record])

            }
            else {
                this.remove_selected_records([record])
            }
        }

    }

    toogleSelectAll(all_selected){
        let selected = []
        if(all_selected){
            selected = [...selected,...this.data.records]
            
        }
        this.set_selected_record(selected)
    }

    pasteValues(start_row, start_column, values){
        values.forEach(function(row, rec_index){
            const next_rec = start_row + rec_index
            let record;
            if(next_rec >= this.data.records.length){
                record = this.data.addRecord({'values':{}})
            }
            else{
                record = this.data.records[start_row + rec_index]
            }
            row.forEach(function(col, col_index){
                const next_col = start_column + col_index
                if(next_col >= this.visible_fields.length){
                    return
                }
                let field = this.visible_fields[start_column + col_index]
                if(!field.get_state_attrs(record).readonly){
                    field.set_value(col, record)
                }
            }.bind(this))        
            
        }.bind(this))
    }

    pasteValuesFromClipboard({columnIndex, rowIndex, event}){
        event.preventDefault()
        const clipboardData = event.clipboardData || event.originalEvent && event.originalEvent.clipboardData;
        const value = clipboardData.getData("Text") || clipboardData.getData("text/plain");
        let rows = value.replace(/"((?:[^"]*(?:\r\n|\n\r|\n|\r))+[^"]+)"/mg, function (match, p1) {
            // This function runs for each cell with multi lined text.
            p1 = p1
                // Replace any double double-quotes with a single
                // double-quote
                .replace(/""/g, '"')
                // .replace(/'"'/g, "")
                // Replace all new lines with spaces.
                .replace(/\r\n|\n\r|\n|\r/g, ' ')
                // .replace(/\t/g, ' ')
            ;
            return p1
        })
        .split(/\r\n|\n\r|\n|\r/g) 
        // Split each line into rows
        // rows = rows.split(/\r\n|\n\r|\n|\r/g) 
        rows = rows.map(function(row){
            row = row.split(/\t/).filter(function(value){
                value = value.trim()
                return Boolean(value)
            })
            return row
        }).filter((row) => row.length > 0);
        // \t
        return this.pasteValues(rowIndex, columnIndex, rows)

       
    }
    setup(view, search, route_state, type=false){
        type = this.readonly ? 'list':false
        super.setup(view, search, route_state, type)
    }

    async do_search(search, force){
        search = search ? search:{'current_search':[]}
        if(this.field_instance){
            
            const field_domain = this.field_instance.get_domain()
            if(field_domain && field_domain.current_search){
                search.current_search = mergeDomain(search.current_search, field_domain.current_search)
            }

        }
        super.do_search(search, force)
        this.set_selected_record([])
        this.set_active_record(false)


    }
    get_group_by_cls(record){
        if(record.grouped_by){
            return 'border-none text-primary font-bold'
        }
        else if(record.groupby_parent){
            return 'italic'
        }
    }

    _get_column_width(column_width, total_width){
        if (typeof column_width === 'function'){
            // Add 20 to width, on render, the spreadsheet substracts 20 to use on the selector
            column_width = column_width(total_width + 20)
        }
        return column_width
    }

    async save_columns_layout({columns_map, width, order_change}){
        let fields_map = {}
        function get_percentage(column_width){
            if (typeof column_width === 'function'){
                // Add 20 to width, on render, the spreadsheet substracts 20 to use on the selector
                column_width = column_width(width + 20)
            }
            return Math.ceil(((column_width*100)/width)).toString().concat('%')
        }   
        for(let index in columns_map){
            const field = this.visible_fields[index].id
            if(field){
                // fields_map[this.visible_fields[index].id] = {'default_width_expr':columns_map[index]}
                fields_map[this.visible_fields[index].id] = {
                    'default_width_expr':get_percentage(columns_map[index]),
                    '_idx':parseInt(index)
                }
            }
            
            
        }
        const abortController = new AbortController();
        let args = { 
            values:{...fields_map},
            order_change:order_change
        }
        await this.connection.dispatch('POST', '/view_handler/update_view_fields', args, false, false, false, abortController)
        this.connection.notifications.addSnack(
            { message: "Vista Actualizada",  persistant:false }
        )
        this.editor.remove_draft_operation('columns_change')
        

    }

    update_fields_width(columns_map, width){
        for(let index in columns_map){
            const field = this.visible_fields[index]
            if(field && field.id){
                field.width = this._get_column_width(columns_map[index], width)
                console.log(field.width)
            }
            
            
        }
    }

    // Add order change as an arg, use the array index to calc sequence on back
    set_columns_changed(columns_map, width, order_change=false){
        if(!this.editor || !this.editor.design_mode){
            return
        }
        
        let values = {'columns_map':columns_map, 'width':width, 'order_change':order_change}
        let current_operation = this.editor.get_draft_operation('columns_change')
        if(current_operation && !order_change){
            values['order_change'] = current_operation.value.order_change
        }
        this.update_fields_width(columns_map, width)
        this.editor.add_draft_operation('columns_change',{
            'name':'Guardar Columnas',
            'value':values,
            'callback':(value) =>{this.save_columns_layout(value)}
        })
    }

    reorder_columns({from, to}){
        let from_field = this.visible_fields[from]
        let to_field = this.visible_fields[to]

        let from_field_index = this.fields.indexOf(from_field)
        let to_field_index = this.fields.indexOf(to_field)
        this.fields[from_field_index] = to_field
        this.fields[to_field_index] = from_field
        
    }



}