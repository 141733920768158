import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite'
import { ReactComponent as ChevronDown } from '../../ui/icons/svg/chevron-down.svg';
import { ReactComponent as ChevronUp } from '../../ui/icons/svg/chevron-up.svg';
import { ReactComponent as MoveColumnIcon } from '../../ui/icons/svg/code.svg';
import Draggable from 'react-draggable';
import { Grid } from 'react-virtualized';
import Tooltip from '../../ui/Tooltip';
import { get_field_align } from './alignment'
import QuickFilter from '../../ui/fields/QuickFilter'

const HeaderCellContent = observer(function HeaderCellContent(props) {
    const {
        screen,
        columnIndex,
        width,
        orderedColumn,
        onColumnClick,
        moveColumn,
        moveColumnStart,
        moveColumnStop,
        columnMoving,
        edition_mode,
        quick_filters
    } = props;

    const visible_fields = screen.visible_fields
    let column = visible_fields[columnIndex]
    let column_description = column.description
    const [columnLabel, setColumnLabel] = useState('')

    useEffect(() => {
        setColumnLabel(visible_fields[columnIndex].description)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible_fields, columnIndex, columnMoving.idx]);

    const sortable = column.sortable
    let contentClassName = 'flex headerCellContent ' + 'justify-'.concat(get_field_align(column.type))
    const filter = screen.filters ? screen.filters.get_quick_filter_fname(column.name) : false

    return (
        <div className={contentClassName} style={{ cursor: sortable ? "pointer" : "" }}>
            <Draggable
                axis='x'
                handle='.ColumnMoveHandler'
                defaultClassName=''
                defaultClassNameDragging='ColumnMoveActive bg-light-selected'
                onStart={(event, data) => moveColumnStart({
                    columnIndex: columnIndex,
                })}
                onStop={(event, data) => {

                    moveColumnStop({
                        columnIndex: columnIndex,
                        width: width

                    })
                }}
                onDrag={(event, data) => {
                    const move_index = columnMoving.idx ? columnMoving.idx : columnIndex
                    setColumnLabel(visible_fields[move_index].description)
                    moveColumn({
                        deltaX: data.x,
                        columnIndex: move_index,
                        lastX: data.lastX,
                        width: width,
                        event: event,
                        data: data
                    })
                }
                }
                position={{
                    x: 0,
                    y: 0
                }}
                zIndex={998}
            >
                <div className={contentClassName}>
                    <div className='flex flex-row'>
                        {edition_mode &&
                            <div className='ColumnMoveHandler w-4 h-4 flex items-center mr-1 text-primary'>
                                <MoveColumnIcon style={{ width: '1em', height: '1em' }} className="fill-current text-xl" />
                            </div>
                        }

                        <span
                            onClick={sortable ? function (e) { onColumnClick(e, column.name) } : () => { }}
                            onContextMenu={function (e) {

                                if (column.edit_field()) {
                                    e.preventDefault()
                                    e.stopPropagation()
                                }
                            }}
                            style={{ textOverflow: 'ellipsis', overflow: 'hidden', cursor: sortable ? "pointer" : "" }}>
                            {column.tooltip ?
                                <Tooltip content={column.tooltip} type={column.tooltip_type}>{column_description}</Tooltip>
                                :
                                columnLabel ? columnLabel : column_description
                            }
                        </span>
                        {sortable &&
                            <React.Fragment>
                                {orderedColumn.name === column.name &&
                                    <React.Fragment>
                                        {orderedColumn.order === 'DESC' ?
                                            <ChevronDown style={{ width: '1em', height: '1em' }} className="fill-current text-xl cursor-pointer w-4 h-4 mr-1 md:mr-4 ml-2" /> : <ChevronUp style={{ width: '1em', height: '1em' }} className="fill-current text-xl cursor-pointer w-4 h-4 mr-1 md:mr-4 ml-2" />
                                            //   <FontAwesomeIcon style={{ paddingLeft: '2px' }} icon={faAngleDoubleDown} /> : <FontAwesomeIcon style={{ paddingLeft: '2px' }} icon={faAngleDoubleUp} />
                                        }
                                    </React.Fragment>

                                }

                            </React.Fragment>
                        }

                    </div>



                    
                    {quick_filters ?
                        <div className="flex pt-2" style={{ height: '40px' }}>
                            {filter ?
                                <div className="flex w-full">
                                    <QuickFilter filter={filter} screen={screen} />
                                </div>
                                :
                                <React.Fragment></React.Fragment>
                            }

                        </div>

                        :
                        <React.Fragment></React.Fragment>
                    }

                </div>


            </Draggable>
            <Draggable
                axis='x'
                defaultClassName='DragHandle'
                defaultClassNameDragging='DragHandleActive'

                onStop={(event, data) => props.onResizeStop({

                    columnIndex: columnIndex,
                    width: width

                })}

                onDrag={(event, data) => props.resizeColumn({
                    deltaX: data.x,
                    columnIndex: columnIndex,
                    lastX: data.lastX,
                    width: width
                })}
                position={{
                    x: 0,
                    y: 0
                }}
                zIndex={999}
            >
                <div className='DragHandleIcon'>
                    ||
                </div>
            </Draggable>
        </div>
    )
})

const SpreadsheetHeader = observer(function SpreadsheetHeader(props) {

    const screen = props.screen
    const visible_fields = screen.visible_fields
    const quick_filters = screen.filters ? screen.filters.quick_filters.length : false
    const orderedColumn = screen.ordered_column
    const columnCount = screen.visible_fields.length;
    const edition_mode = screen.editor ? screen.editor.design_mode : false
    const width = props.width

    const onColumnClick = (e, column_name) => {
        //DISPATCH ORDER TO SCREEN
        screen.set_order(column_name)

    }
    function renderHeaderCell({ columnIndex, key, rowIndex, style }) {

        if (columnIndex < 1) {
            return;
        }

        let column = screen.visible_fields[columnIndex]
        // const columnMoving = props.columnMoving ? props.columnMoving.idx == columnIndex : false

        let containerClass = "headerCell "
        // const contentClassName = 'headerCellContent ' + 'justify-'.concat(get_field_align(column.type))


        return (
            <div className={containerClass} key={key} style={style}>

                <HeaderCellContent
                    screen={screen}
                    // visible_fields={visible_fields}
                    columnIndex={columnIndex}
                    // sortable={sortable}
                    orderedColumn={orderedColumn}
                    onColumnClick={onColumnClick}
                    width={width}
                    moveColumnStart={props.moveColumnStart}
                    moveColumnStop={props.moveColumnStop}
                    moveColumn={props.moveColumn}
                    columnMoving={props.columnMoving}
                    resizeColumn={props.resizeColumn}
                    onResizeStop={props.onResizeStop}
                    edition_mode={edition_mode}
                    quick_filters={quick_filters}
                />




                {/* {quick_filters ?
                    <div className="flex pt-2" style={{ height: '40px' }}>
                        {filter ?
                            <div className="flex w-full">
                                <QuickFilter filter={filter} screen={screen} />
                            </div>
                            :
                            <React.Fragment></React.Fragment>
                        }

                    </div>

                    :
                    <React.Fragment></React.Fragment>
                } */}



            </div>
        )

    }
    return (
        <Grid
            className="HeaderGrid"
            // columnWidth={({index}))props.columnWidth}
            columnWidth={({ index }) => { return props.columnWidth({ index, width }) }}
            columnCount={columnCount}
            height={props.rowHeight}
            overscanColumnCount={0}
            cellRenderer={renderHeaderCell}
            rowHeight={props.rowHeight}
            rowCount={1}
            scrollLeft={props.scrollLeft}
            ref={props.gridHeaderRef}
            // width={width - scrollbarSize()}
            width={process.env.NODE_ENV === 'test' ? 900 : props.width}
            quick_filters={quick_filters}
        />

    )
})

export default SpreadsheetHeader